/** @jsx jsx */
import "./../normalizer.css"
import { jsx } from "theme-ui"
import { ReactNode } from "react"

/**
 * Type definition for props required for "BlankLayout" component.
 *
 * @interface
 */
interface LayoutProps {
  children: ReactNode
}

/**
 * Component rendering child components with no "Header" or "Footer".
 *
 * @param {LayoutProps} param
 *
 * @returns {JSX.Element}
 */
const BlankLayout = ({ children }: LayoutProps): JSX.Element => {
  return (
    <div sx={{ fontFamily: "body", bg: "white", m: 0 }}>
      <main>{children}</main>
    </div>
  )
}

export default BlankLayout
