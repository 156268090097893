import React from "react"
import SEO from "../components/seo"
import NotFound from "../components/notfound"
import BlankLayout from "../components/layouts/blank"

/**
 * Component rendering the 404 page of the site.
 *
 * @returns {JSX.Element}
 */
const NotFoundPage = (): JSX.Element => {
  // Render the component using "BlankLayout"
  return (
    <BlankLayout>
      <SEO title="404: Page Not found" />
      <NotFound />
    </BlankLayout>
  )
}

export default NotFoundPage
