/** @jsx jsx */
import {
  notFoundTextStyles,
  notFoundTitleStyles,
  notFoundButtonStyles,
  notFoundContainerStyles,
} from "./styles"
import { useIntl } from "react-intl"
import { Link } from "gatsby-plugin-intl"
import { jsx, Flex, Styled } from "theme-ui"
import { homeRoute } from "../../routes/main"
import { translate } from "../../helpers/grammar"

/**
 * Component rendering the "NotFound" section of the site.
 *
 * 1. Heading (H1)
 * 2. Heading (H2)
 * 3. Button (Home/Index Page)
 *
 * Used on "404" page.
 *
 * @returns {JSX.Element}
 */
const NotFound = (): JSX.Element => {
  const intl = useIntl()
  const homeUrl = homeRoute({ intl })

  return (
    <Flex sx={notFoundContainerStyles}>
      <Styled.h1 sx={notFoundTitleStyles}>404</Styled.h1>
      <Styled.h2 sx={notFoundTextStyles}>
        {translate(intl, "notFound.text")}
      </Styled.h2>
      <Link to={homeUrl.path} sx={notFoundButtonStyles}>
        {translate(intl, "notFound.buttonText")}
      </Link>
    </Flex>
  )
}

export default NotFound
