import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../helpers/theme"

export const notFoundContainerStyles: SxStyleProp = {
  width: "100%",
  height: "100vh",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
}

export const notFoundTitleStyles: SxStyleProp = {
  lineHeight: 1,
  fontWeight: "bold",
  fontSize: createResponsiveStyles("100px", "200px"),
}

export const notFoundTextStyles: SxStyleProp = {
  pb: createResponsiveStyles(5, 6, 6),
  fontSize: createResponsiveStyles("25px", "40px"),
}

export const notFoundButtonStyles: SxStyleProp = {
  variant: "buttons.primary",
}
